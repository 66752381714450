import React, { useState, useEffect } from 'react';
import Gal1 from './Image/cb14.png';
import Gal2 from './Image/cb15.png';
import Gal3 from './Image/cb16.png';
import Gal4 from './Img/caterers.jpeg';
import Gal5 from './Img/caterers.png';
import Gal6 from './Img/chhota-imambara.jpg';
import Gal7 from './Img/dinning.jpg';
import gal8 from './Img/gal1.jpg';
import gal9 from './Img/gal2.jpg';
import gal10 from './Img/gal3.jpg';
import gal11 from './Img/gal4.jpg';
import Footer from './Footer';

const Gallery = () => {
  const [uploadedImages, setUploadedImages] = useState([]); // State for uploaded images
  const [selectedImages, setSelectedImages] = useState([]); // Temporary images before uploading

  // Load uploaded images from localStorage on component mount
  useEffect(() => {
    const savedImages = JSON.parse(localStorage.getItem('uploadedImages')) || [];
    setUploadedImages(savedImages);
  }, []);

  // Save uploaded images to localStorage whenever the state changes
  useEffect(() => {
    if (uploadedImages.length > 0) {
      localStorage.setItem('uploadedImages', JSON.stringify(uploadedImages));
    }
  }, [uploadedImages]);

  // Handle file selection
  const handleFileSelection = (e) => {
    const files = Array.from(e.target.files); // Get selected files
    const tempImages = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (event) => {
          resolve({
            id: Date.now() + Math.random(),
            name: file.name,
            url: event.target.result, // Convert to Base64 for preview
          });
        };
        reader.readAsDataURL(file);
      });
    });

    // Add selected images to temporary state
    Promise.all(tempImages).then((images) => {
      setSelectedImages((prev) => [...prev, ...images]);
    });
  };

  // Handle upload button click
  const handleUpload = () => {
    setUploadedImages((prev) => [...prev, ...selectedImages]);
    setSelectedImages([]); // Clear temporary state after upload
  };

  return (
    <>
      <center>
        <h1>Gallery</h1>
      </center>

      {/* Upload Images Section */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileSelection}
          style={{ marginBottom: '10px' }}
        />
        <button
          onClick={handleUpload}
          style={{
            marginLeft: '10px',
            padding: '8px 15px',
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Upload
        </button>
       
      </div>

      {/* Gallery Display */}
      <div className="gallery" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {/* Static Images */}
        {[Gal1, Gal2, Gal3, Gal4, Gal5, Gal6, Gal7, gal8, gal9, gal10, gal11].map((img, index) => (
          <div className="image-container" key={index} style={{ margin: '10px' }}>
            <img
              src={img}
              alt={`Static ${index}`}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            />
          </div>
        ))}

        {/* Uploaded Images */}
        {uploadedImages.map((image) => (
          <div className="image-container" key={image.id} style={{ margin: '10px' }}>
            <img
              src={image.url}
              alt={image.name}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            />
            <p style={{ fontSize: '12px', wordBreak: 'break-word' }}>{image.name}</p>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
